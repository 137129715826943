import { DesignPoint } from '@/ship/Models/Design';
import { pick } from 'lodash';
import { between } from '@/utils/Helper';
import { RemarkRejectionEnum } from '@/ship/Enums/DesignEnums';

export interface DrawingOptions {
    points: DesignPoint[];
    text?: string;
    selected?: boolean;
    status?: RemarkRejectionEnum;
    scaleIndex?: number;
    isReact?: boolean;
}

export interface DrawingRectangleOptions extends DrawingOptions {
    drawPin?: boolean;
}

/**
 * brightColor - color of area
 * stringColor - color of moving pin
 */
export const palette = {
    brightColor: 'rgba(38, 200, 124, 0.3)',
    strongColor: '#30C07C',
    darkFontColor: '#484848',
    lightFontColor: '#FFFFFF',
};
// Default pin size (values increased in 2.5 - default image scaling)
export const pinHeight: number = 115;
export const pinWidth: number = 100;
export const pinTextSize: number = 30;
export const pinTextOffset: number = 20;
export const pinHeightOffset: number = 20;

export class CanvasDrawer {
    ctx!: CanvasRenderingContext2D;

    pinImage: HTMLImageElement = new Image();
    pinSelectedImage: HTMLImageElement = new Image();
    pinCompletedImage: HTMLImageElement = new Image();
    pinRejectedImage: HTMLImageElement = new Image();
    backgroundImage: HTMLImageElement = new Image();

    constructor(ctx: CanvasRenderingContext2D) {
        this.ctx = ctx;

        // Static images
        this.pinImage.src = require(`@/assets/pin-default.svg?inline`);
        this.pinSelectedImage.src = require(`@/assets/pin-selected.svg?inline`);
        this.pinCompletedImage.src = require(`@/assets/pin-completed.svg?inline`);
        this.pinRejectedImage.src = require(`@/assets/pin-rejected.svg?inline`);
    }

    public setBackground(image: HTMLImageElement) {
        this.backgroundImage = image;
    }

    public drawFigure(options: DrawingOptions) {
        switch (options.points?.length) {
            case 1:
                return this.drawPin(options);
            case 2:
                return this.drawRectangle({ ...options, drawPin: true });
        }
    }

    public drawBackground(scaleIndex: number) {
        this.ctx.drawImage(
            this.backgroundImage,
            0,
            0,
            this.backgroundImage.width * scaleIndex,
            this.backgroundImage.height * scaleIndex,
        );
    }

    public drawRectangle(args: DrawingRectangleOptions) {
        const { points, scaleIndex = 1 } = args;
        if (args.points.length !== 2) return;

        this.ctx.beginPath();

        this.ctx.fillStyle = palette.brightColor;
        this.ctx.strokeStyle = palette.strongColor;

        const { width, height } = this.findRectangleParams(points);
        const [startPoint] = points;

        const params = [startPoint.x, startPoint.y, width, height].map((parameter) => parameter * scaleIndex) as [
            number,
            number,
            number,
            number,
        ];

        this.ctx.fillRect(...params);
        this.ctx.strokeRect(...params);

        this.ctx.fill();
        this.ctx.stroke();
        this.ctx.closePath();

        if (args.drawPin) {
            const point = { x: startPoint.x + width / 2, y: startPoint.y + height / 2 };
            const pinOptions = {
                points: [point],
                ...pick(args, ['text', 'selected', 'completed', 'scaleIndex', 'status']),
                isReact: true,
            };
            this.drawPin(pinOptions);
        }
    }

    public drawPin(args: DrawingOptions) {
        const { points, selected, status, text, scaleIndex = 1, isReact = false } = args;
        if (points.length !== 1) return;

        const [{ x, y }] = points;

        this.ctx.beginPath();

        let pin = this.pinImage;
        switch (status) {
            case RemarkRejectionEnum.Completed:
                pin = this.pinCompletedImage;
                break;
            case RemarkRejectionEnum.Rejected:
                pin = this.pinRejectedImage;
                break;
            case RemarkRejectionEnum.None:
                this.pinImage.src = '';
                break;
        }

        this.ctx.drawImage(
            selected
                ? this.pinSelectedImage
                : isReact && status === RemarkRejectionEnum.Created
                ? this.pinCompletedImage
                : pin,
            (x - pinWidth / 2) * scaleIndex,
            (y - pinHeight + pinHeightOffset) * scaleIndex,
            pinWidth * scaleIndex,
            pinHeight * scaleIndex,
        );

        const offsetTextY = y - (pinHeight - pinTextOffset) / 2;
        this.drawText(
            x,
            offsetTextY,
            text,
            selected || status !== RemarkRejectionEnum.Created || (status === RemarkRejectionEnum.Created && isReact),
            scaleIndex,
        );

        this.ctx.closePath();
    }

    public drawText(x: number, y: number, text: string = '', light: boolean = false, scaleIndex: number = 1) {
        if (!text) return;

        this.ctx.beginPath();

        this.ctx.fillStyle = light ? palette.lightFontColor : palette.darkFontColor;
        this.ctx.textAlign = 'center';
        this.ctx.textBaseline = 'middle';
        this.ctx.font = `${pinTextSize * scaleIndex}px Roboto`;
        this.ctx.fillText(text, x * scaleIndex, y * scaleIndex);
        this.ctx.fill();

        this.ctx.closePath();
    }

    public findRectangleParams(points: DesignPoint[]) {
        const [start, end] = points;
        const height = Math.abs(end.y - start.y);
        const width = Math.abs(end.x - start.x);

        return { height, width };
    }

    public pointInRectangle(points: DesignPoint[], x: number, y: number): boolean {
        const [start, end] = points;
        if (points.length !== 2 || !start || !end) return false;

        const { width, height } = this.findRectangleParams(points);
        const pin = [{ x: start.x + width / 2, y: start.y + height / 2 }];

        return (between(x, start.x, end.x) && between(y, start.y, end.y)) || this.pointInPin(pin, x, y);
    }

    public pointInPin(points: DesignPoint[], x: number, y: number): boolean {
        const [point] = points;
        if (!point) return false;

        return (
            between(x, point.x - pinWidth / 2, point.x + pinWidth / 2) &&
            between(y, point.y - pinHeight + pinHeightOffset, point.y)
        );
    }
}

export default CanvasDrawer;
